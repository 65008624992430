import { BoxedLayout } from '../../layout';

import Hero from '../../components/Hero/Hero';
import Why from '../../components/Why/Why';
import Math from '../../components/Math/Math';
import Features from '../../components/Features/Features';
import HowItWorks from '../../components/HowItWorks/HowItWorks';

import './home.css'


function Home() {
  return (
    <>
      <Hero />
      <BoxedLayout>   
          <Why />
      </BoxedLayout>
      <Math />
      <Features />
      <HowItWorks />
    </>     
  );
}

export default Home;
