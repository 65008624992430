import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { sendResetPasswordEmailAPI } from '../../services/api';

function ForgotPassword() {
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')


    const sendPasswordResetEmail = (e) => {
        e.preventDefault();
        const data = {
            email: email
        }
        sendResetPasswordEmailAPI(data)
            .then(response => (response.data))
            .then(response => {
                setMessage(response.message)
            }).catch(error => {
               setMessage('Unknown error occured')
            })
    }

    return (
        <Row>
            <Col md={4} sm={12}></Col>
            <Col md={4} sm={12}>
                <Form onSubmit={(e) => { sendPasswordResetEmail(e) }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value)}} required={true}/>
                        <Form.Text className="text-muted">
                           {message}
                        </Form.Text>
                    </Form.Group>
            
                    <Button variant="primary" type="submit" className='w-100'>
                        Submit
                    </Button>
                </Form>
            </Col>
            <Col md={4} sm={12}></Col>
        </Row>
    );
  }
  
  export default ForgotPassword;