import { useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";




import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { resetPasswordAPI } from '../../services/api';
import { notify } from '../../services/client';

function ResetPassword() {
    const search = useLocation().search;
    const email = new URLSearchParams(search).get("email");
    const token = new URLSearchParams(search).get("token");
    
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate();


    const resetPassword = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password,
            password_confirmation: confirmPassword,
            token: token
        }
        resetPasswordAPI(data)
            .then(response => (response.data))
            .then(response => {
                setMessage(response.message)
                if (response.success) {
                    notify(response.message, 'success')
                    navigate('/login')
                }
            }).catch(error => {
                if (error.response?.data?.errors?.password) {
                    setMessage(error.response?.data?.errors.password[0])
                    return;
                }
                setMessage('Unknown error occured')
            })
    }

    return (
        <Row>
            <Col md={4} sm={12}></Col>
            <Col md={4} sm={12}>
                <Form onSubmit={(e) => { resetPassword(e) }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} disabled={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value)}} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value)}} required={true}/>
                        <Form.Text className="text-muted">
                           {message}
                        </Form.Text>
                    </Form.Group>
            
                    <Button variant="primary" type="submit" className='w-100'>
                        Submit
                    </Button>
                    <Button variant="outline-secondary"  className='mt-4 w-100'>
                        <Link to='/login' style={{textDecoration: 'none'}}>Login</Link>
                    </Button>
                </Form>
            </Col>
            <Col md={4} sm={12}></Col>
        </Row>
    );
  }
  
  export default ResetPassword;