function DownloadBtn({url}) {
    return (
        <a href={url}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8.5V14.5M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" stroke="#1DA1E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 12.5L12 15.5L15 12.5" stroke="#1DA1E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </a>
    );
}

export default DownloadBtn;