import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LicenseBox from '../../components/LicenseBox/LicenseBox';
import './license.css'
import { Container } from 'react-bootstrap';

function License() {
  return (
    <>
      <Row className='main-container'>
        <Col>
          <Container>
            <span className='main-heading'>License Subscriptions & Pricing</span>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <LicenseBox />
          </Container>
        </Col>
      </Row>
    </>
  );
}

export default License;
