import { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import ProgressLoader from '../ProgressLoader/ProgressLoader';
import './license-box.css';

import { getPlansAPI, getPaymentIntentAPI, createSubscriptionAPI, getSubscriptionAPI } from "../../services/api";
import {  notify, getPackage } from "../../services/client";

import { monthly_plan, yearly_plan, nine_months_plan, stripe_pk } from '../../config';

function LicenseBox() {
  const PlanTypes = {
    MONTHLY: 'Monthly',
    YEARLY: 'Annually',
    NINE_MONTHS: 'Nine Months'
  }

  const navigate = useNavigate();

  const [stripeData, setStripeData] = useState(null)
  const [paymentElementData, setPaymentElementData] = useState(null)
  const [clientSecretData, setClientSecretData] = useState(null)

  const [plan, setPlan] = useState(null)
  const [yearlyPlan, setYearlyPlan] = useState(null);
  const [monthlyPlan, setMonthlyPlan] = useState(null);
  const [nineMonthsPlan, setNineMonthsPlan] = useState(null);

  const [show, setShow] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [hidePurchase, setHidePurchase] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const features = ['MacOS Support', 'Billed at the start of each month*']
  const features2 = ['No Advertisements', 'Cancel anytime']


  const createSubscription =  async () => {
    paymentElementData.update({ disabled: true });
    setLoadingStripe(true);
    setDisableBtn(true)
    stripeData
        .confirmCardSetup(clientSecretData, {
            payment_method: {
                card: paymentElementData
            },
        })
        .then(function(result) {
            const displayError = document.getElementById('card-errors');
            if ( result.error ) {
                displayError.textContent = result.error.message;
                paymentElementData.update({ disabled: false });
                setLoadingStripe(false)
                setDisableBtn(false)
                return;
            }
            displayError.textContent = '';
            const subscription = {
                productId: plan.id,
                priceId: plan.price.id,
                paymentMethod: result.setupIntent.payment_method
            }
            createSubscriptionAPI(subscription)
                .then(response => (response.data))
                .then(data => {
                    paymentElementData.update({ disabled: false });
                    setLoadingStripe(false)
                    notify(data.message, 'success');
                    navigate('/account/detail')
                })
                .catch(error => {
                    paymentElementData.update({ disabled: false });
                    setLoadingStripe(false)
                    setDisableBtn(false)
                    console.log(error)
                })
        });
  }

  const buyLicense = async (e, type) => {
    setLoadingStripe(true);
    handleShow()

    const stripe = await loadStripe(stripe_pk);
    setStripeData(stripe);

    getPaymentIntentAPI()
        .then(response => (response.data))
        .then(data => {
            const client_secret = data.intent.client_secret
            const elements = stripe.elements({
                clientSecret: client_secret,
            });

            const options = {
                style: {
                    base: {
                        color: "#32325d",
                        fontFamily: 'DM Sans, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                        color: "#32325d"
                        }
                    }
                }
            }
            const paymentElement = elements.create('card', options);            
            paymentElement.mount('#card-element');  
            paymentElement.on('ready', (e) => {
                paymentElement.focus()
            })
            setClientSecretData(client_secret);
            setPaymentElementData(paymentElement);
            setLoadingStripe(false);
            
            const displayError = document.getElementById('card-errors');
            
            paymentElement.on('change', function(event) {
                if (event.complete) {
                    setDisableBtn(false);
                    return;
                }
                
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });
        })
        .catch(error => {
            setLoadingStripe(false);
            console.log(error)
        })
  }
  
  const changePlan = (id) => {
        if ( id === yearly_plan ) {
            setPlan(yearlyPlan);
            return;
        }
        if ( id === monthly_plan ) {
            setPlan(monthlyPlan);
            return;
        }
        setPlan(nineMonthsPlan);        
  }

  const getPlans = async () => {
    await getPlansAPI()
            .then(response => (response.data))
            .then(data => {
                let nine_months = data.find(plan => plan.id === nine_months_plan)
                nine_months = {
                    ...nine_months,
                    per: 'Nine Mo'
                }
                setNineMonthsPlan(nine_months)
                setPlan(nine_months)

                let yearly = data.find(plan => plan.id === yearly_plan)
                yearly = {
                    ...yearly,
                    per: 'Year'
                }
                setYearlyPlan(yearly)                
                let monthly = data.find(plan => plan.id === monthly_plan)
                monthly = {
                    ...monthly,
                    per: 'Mo'
                }
                setMonthlyPlan(monthly)

                const selected_package = getPackage();
                console.log('SP::: ',selected_package)
                if ( selected_package ) {
                    if ( selected_package == 1 ) {
                        setPlan(monthly)
                    }
                    else if ( selected_package == 2) {
                        setPlan(nine_months)
                    }
                    else {
                        setPlan(yearly)
                    }
                }
                
            })
            .catch(error => {
                console.log(error)
            })
  }

  const getSubscription = () => {
    getSubscriptionAPI()
      .then(response => (response.data))
      .then((data) => {
        if ( data.length === 0) {
          setHidePurchase(false);
        }
    })
  }


  useEffect(() => {
    getPlans();
    getSubscription()
  },[]);

  return (
    plan ?
        <div className="license-box">
            <div className="first-row">
                        <Row className="align-items-center mb-4">
                            <Col sm={12}>
                                <span>Choose your plan</span>
                            </Col>
                            <Col sm={12}>
                                <ButtonGroup aria-label="VFI Plans" className="plan-switch">
                                    <Button variant="default" className={ plan.id === monthly_plan ? 'active-plan' : '' } onClick={() => { changePlan(monthly_plan)}} >{PlanTypes.MONTHLY}</Button>
                                    <Button variant="default" className={ plan.id === nine_months_plan ? 'active-plan' : '' } onClick={() => { changePlan(nine_months_plan) }} >{PlanTypes.NINE_MONTHS}</Button>
                                    <Button variant="default" className={ plan.id === yearly_plan ? 'active-plan' : '' } onClick={() => { changePlan(yearly_plan) }} >{PlanTypes.YEARLY}</Button>
                                </ButtonGroup>  
                            </Col>
                        </Row>
                        <span className="title">{plan.title}</span>
                        <div className="features">
                            {
                                features.map((feature, idx) => (<div key={`package-feature-${idx}`} className='d-flex align-items-center mb-1'><span className="dot"></span><span>{feature}</span></div>))
                            }
                        </div>
            </div>
            <div className="hr-line"></div>
            <div className="second-row">
                <div className="price-div">
                    <span className="price">US&nbsp;&nbsp;<sup>$</sup>{`${(plan.price.unit_amount / 100.0)}`} <span className="per">&nbsp;&nbsp;/ {plan.per}</span> </span>
                    <span className="license-text">per license</span>
                </div>
                <div className="features-2">
                    {
                        features2.map((feature, idx) => (
                            <div key={`package-feature-2-${idx}`} className='d-flex align-items-center mb-1'>
                                <span className="check">
                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 9.99998L0 5.99998L1.4 4.59998L4 7.19998L10.6 0.599976L12 1.99998L4 9.99998Z" fill="#087CFA"/>
                                    </svg>
                                </span>
                                <span>{feature}</span>
                            </div>))
                    }
                </div>
                <ul className="terms"><li><span>Terms and conditions apply.</span> A single license key cannot be used by multiple parties.</li></ul>
                {
                    !hidePurchase && 
                    <div className="buy-div">
                        <Button className="buy" onClick={(e) => { buyLicense(e, plan.id) }}>Purchase</Button>
                    </div>
                } 
                {
                    hidePurchase && 
                    <div className="buy-div">
                       <p className="buy" title="You have already an active subscription." 
                       style={{cursor: 'not-allowed', 
                        color: 'white',
                        opacity: '0.65'
                       }}>Purchase</p>
                    </div>
                    
                }            
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                <Modal.Title>Purchase License</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <div id="card-element"></div>
                    <div id="card-errors" className="text-danger"></div>
                </Modal.Body>
                <Modal.Footer>
                {
                    loadingStripe && <ProgressLoader size="sm"/> 
                }
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={createSubscription} disabled={disableBtn}>
                    Purchase
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    :   <div className="mt-4 pt-4"><ProgressLoader/></div>
  );
}

export default LicenseBox;