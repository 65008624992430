import React from "react";
import { Outlet } from 'react-router-dom';

import { Container } from "react-bootstrap";
import Footer from "../components/Footer/Footer";


const AuthLayout = () => (
    <>
    
        <Container style={{marginTop:'5rem'}}>
            <Outlet />
        </Container>
        <Footer />
    </>
);

export default AuthLayout;