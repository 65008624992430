import { NavLink } from "react-router-dom";
import './sidebar.css'

function Sidebar({items}) {
  return (
    <div className='sidebar'>
        {
            items.map(item => (
                <NavLink 
                  key={item.link}
                  to={item.link}
                  className={({ isActive }) =>
                    isActive ? 'sidebar-item active' : 'sidebar-item'
                  }
                >{item.title}</NavLink>
            ))
        }
    </div>
  );
}

export default Sidebar;