import { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import HomeNavbar from './HomeNavbar/HomeNavbar';
import AccountNavbar from './AccountNavbar/AccountNavbar';

import './navbar.css'
import logo from '../../assets/logo.png'

function HomeLink({hideoffCanvas, isHome}) {
    const text_color = isHome ? 'text-white': 'text-black'
    return (
        <a href="https://virtualflightinstructor.co" className={`nav-link d-flex align-items-center`} onClick={() => {
            hideoffCanvas()
        }}>
            <img
            alt=""
            src={logo}
            width="48"
            height="48"
            className="d-inline-block align-top me-2"
            />{' '}
            <div className={`text-brand ${text_color} d-flex flex-column`}>
                <span>Virtual Flight</span>
                <span>Instructor</span>
            </div>
        </a>
    )
}

function MainNavbar({is_home = false}) {
    const [activeLink, setActiveLink] = useState('')
    const expand = 'lg';
    const id = `offcanvasNavbar-expand-${expand}`
    const zIndex = 2000;

    const hideoffCanvas = () => {
        const classLists = document.getElementById(id).classList;
        if ( !classLists.contains('show') ) return;
        document.getElementsByClassName('navbar-toggler')[0].click()
    }

    return (
        <Navbar expand={expand} className='main-nav d-flex justify-content-center w-100'>
            <Container>
                <Navbar.Brand className={`d-none d-${expand}-block`}>
                    <HomeLink hideoffCanvas={hideoffCanvas} isHome={is_home}/>                    
                </Navbar.Brand>
                <Navbar.Toggle className={`position-fixed`} aria-controls="responsive-navbar-nav" style={{zIndex:zIndex,backgroundColor:'white'}} />
                <Navbar.Offcanvas
                    id={id}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    scroll= {true}
                    backdrop={false}
                    style={{zIndex:(zIndex+100)}}
                    >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <HomeLink hideoffCanvas={hideoffCanvas} />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='d-flex justify-content-between justify-content-lg-end'>
                        {
                            is_home ?  <HomeNavbar activeLink={activeLink} setActiveLink={setActiveLink} hideoffCanvas={hideoffCanvas} /> :
                            <AccountNavbar activeLink={activeLink} setActiveLink={setActiveLink} hideoffCanvas={hideoffCanvas} />
                        }                        
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default MainNavbar;