import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import { isLoggedIn } from '../../../services/client';

import download_windows from '../../../assets/download_windows.png'
import download_mac from '../../../assets/download_mac.png'

function HomeNavbar(props) {
  const { activeLink, setActiveLink, hideoffCanvas } = props;
  const logged_in = isLoggedIn();

  return (
      <Nav className='d-flex align-items-start align-items-lg-center'>
          <Nav.Link href="#features" className={`${activeLink === 'features' ? 'active' : ''}`} onClick={() => {
            setActiveLink('features')
            hideoffCanvas()
            }}>Features</Nav.Link>
          <Nav.Link href="#pricing" className={`${activeLink === 'pricing' ? 'active' : ''}`} onClick={() => {
            setActiveLink('pricing')
            hideoffCanvas()
            }}>Pricing</Nav.Link>            
          <Nav.Link href="#contact" className={`${activeLink === 'contact' ? 'active' : ''}`} onClick={() => {
            setActiveLink('contact')
            hideoffCanvas()
            }}>Contact</Nav.Link>
          {
            !logged_in &&
            <>
              {/* <Link to='/signup' className={`nav-link ${activeLink === 'signup' ? 'active' : ''}`} onClick={() => {
              setActiveLink('signup')
              hideoffCanvas()
              }}>Create an Account</Link> */}
              <Link to='/login' className={`nav-link d-none d-lg-block ${activeLink === 'login' ? 'active' : ''}`} onClick={() => {
              setActiveLink('login')
              hideoffCanvas()
              }}>Login</Link>
              <Link to='/login' className={`nav-link d-lg-none ${activeLink === 'login' ? 'active' : ''}`} onClick={() => {
              setActiveLink('login')
              hideoffCanvas()
              }}>Login</Link>
            </>
          }
      
          {
            logged_in &&
            <>
              <Link to="/account/detail" className={`nav-link ${activeLink === 'detail' ? 'active' : ''}`} onClick={() => {
              setActiveLink('detail')
              hideoffCanvas()
              }}>My Account</Link>
            </>
          }
          <div className='ms-0 ms-lg-4'>
            <img
              src={download_windows}
              className="d-inline-block me-2"
              alt="React Bootstrap logo"
              width={104}
              height={31}
            />
          
            <img
              src={download_mac}
              className="d-inline-block"
              alt="React Bootstrap logo"
              width={104}
              height={31}
            />
          </div>
      </Nav>  
  );
}

export default HomeNavbar;