
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate,  useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { signUpUserAPI } from '../../services/api';
import { setAccessToken, setPackage, isLoggedIn } from '../../services/client';


function SignUp() {
    const params = useParams();
    console.log(params)
    if ( params && params.package_id ) {
        setPackage(params.package_id)
    }

    const errorObject = {
        name: '',
        email: '',
        password: ''
    }
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(errorObject)

    const navigate = useNavigate();

    const signUpUser = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        setError(errorObject);
        const user = {
            name: name,
            email: email,
            password: password
        }

        signUpUserAPI(user)
        .then(response => (response.data))
        .then(response => {
            e.target.disabled = false;        
            if ( response.name || response.email || response.password ) {
                setError({
                    name: response.name ? response.name[0] : '',
                    email: response.email ? response.email[0] : '',
                    password: response.password ? response.password[0] : ''
                })
                
                return;
            }
            setAccessToken(response.access_token)
            navigate('/license')
            
        }).catch(error => {
            e.target.disabled = false;
            setError({
                name: '',
                email: '',
                password: 'An unknown error occured. If it is persists, Please react out to support.'
            })
        })
    } 

    const submitOnEnter = () => {        
        document.getElementById('signup-form').addEventListener('keydown', function (a) {           
            if (a.key === 'Enter') {
                const signup = document.getElementById('signup-btn')
                signup.click();
            }
        }, false);
    }

    useEffect(() => {        
        if( isLoggedIn() ) {
            navigate('/account/detail');
            return;
        }
        submitOnEnter();
    });

    return (
        <Container className='mt-4'>
            <Row className='d-flex justify-content-center' style={{paddingTop: '25px'}}>
                <Col lg={4} md={4} sm={12}>
                    <h3>Sign up for a new account</h3>
                    <Form id="signup-form" className='mt-4'>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your Name" value={name} onChange={(e) => { setName(e.target.value)}} />
                            <Form.Text className="text-danger">
                                {error.name}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value)}}/>
                            <Form.Text className="text-danger">
                                {error.email}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value)}}/>
                            <Form.Text className="text-danger">
                                {error.password}
                            </Form.Text>
                        </Form.Group>
                        <Button variant="secondary" className='login-btn mt-2' id="signup-btn" onClick={(e) => signUpUser(e)}>
                            Sign Up
                        </Button>
                        <Button variant="outline-secondary" className='login-btn mt-1'>
                            <Link to='/login'>Login</Link>
                        </Button>
                        
                    </Form>
                </Col>
            </Row>
        </Container>       
    );
}

export default SignUp;