import Container from 'react-bootstrap/Container';
import './hero.css'
import { BoxedLayout } from '../../layout';

import hero from '../../assets/hero.png'
import hero_mb from '../../assets/hero_mb.png'

function Hero() {
  return (
    <div className='hero'>
      <img src={hero} alt="Pilot" className="image d-none d-lg-block"></img>
      <img src={hero_mb} alt="Pilot" className="image_mb d-lg-none"></img>
      <BoxedLayout>
        <Container className='content d-flex flex-column mx-0 px-0'>
          <span className='title vfi-family-montserrat text-white fst-normal'>Learn to fly at home.<br/> 
            Graduate in less time.<br/>
            Practice. Practice. Practice.</span>
          <span className='text vfi-family-dm-sans text-white fst-normal'>Virtual Flight Instructor is the premier solution to enable deliberate practice. This virtual trainer helps you build muscle memory at home for free so every hour in the plane is that much more effective. </span>
          <button className='vfi-btn vfi-btn-purple mt-4'>
            Learn more
          </button>
        </Container>
      </BoxedLayout>
    </div>        
  );
}

export default Hero;