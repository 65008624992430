import React from "react";
import { Outlet } from 'react-router-dom';

import MainNavbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const DefaultLayout = ({is_home = false}) => (
  <>
    <MainNavbar is_home={is_home}/>
    <Outlet />
    <Footer is_home={is_home} />
  </>
);

export default DefaultLayout;