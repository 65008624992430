import { BoxedLayout } from '../../layout';
import './math.css'

function Math() {
  const title = 'Here’s the math:';
  const math = 'Flight training takes 10+ months. Over that time, the average student flies 73 hrs. @ $250/hr. FFA requires 40 hrs. Few can do it in that period. Why? They don’t practice at home. A 30% reduction would save 22 hours or $5,500.';
  const end_text = 'Deliberate practice makes all the difference. '
  return (
    <div className='math vfi-bg-light-grey'>
        <BoxedLayout>
          <div className='d-flex flex-column vfi-family-inter fst-normal'>
            <span className='title vfi-text-grey'>{title}</span>
            <span className='math-text vfi-text-black'>{math}</span>
            <span className='end-text text-end vfi-text-grey'>{end_text}</span>
          </div>
        </BoxedLayout>
    </div>        
  );
}

export default Math;