import './why.css'

function Why() {
  const why = 'Consistent practice can reduce flight training time in the cockpit by up to 30%';
  return (
    <div className='why d-flex justify-content-center fst-italic vfi-family-inter vfi-text-pink'>
        <span className='title'>{why}</span>
    </div>        
  );
}

export default Why;