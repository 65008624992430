import { toast } from 'react-toastify';

const user_key = 'user'
const token_key = 'access_token'

const addKeyToStorage = (key, object) => {
    localStorage.setItem(key, JSON.stringify(object))
}

const removeKeyFromStorage = (key) => {
    localStorage.removeItem(key)
}

const getKeyFromStorage = (key) => {
    const obj = localStorage.getItem(key)
    return JSON.parse(obj);
}

export const setUser = (user) => {
    addKeyToStorage(user_key, user)
}

export const getUser = () => {
    return getKeyFromStorage(user_key)
}

export const setAccessToken = (token) => {
    addKeyToStorage(token_key, token)
}

export const getAccessToken = () => {
    return getKeyFromStorage(token_key)
}

export const isLoggedIn = () => {
    if (! getKeyFromStorage(token_key) ) return false;
    return true;
}

export const setPackage =  (package_id) => {
    sessionStorage.setItem('package', package_id)
}

export const getPackage =  () => {
    return sessionStorage.getItem('package')
}

export const Logout = () => {
    removeKeyFromStorage(token_key);
    window.location.href = '/login';
}

export const notify = (message, type) => {
    if ( type === 'success')
    {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    else {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
    }
}


