import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container"

import { updatePasswordAPI} from '../../../services/api';




function Setting() {

    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')

    const updatePassword = (e) => {
        e.preventDefault();
        const data = {
            new_password: password
        }
        updatePasswordAPI(data)
            .then(response => (response.data))
            .then(response => {
                setMessage(response.message)
            }).catch(error => {
                console.log(error.response)
                if (error.response?.data?.errors?.new_password) {
                    setMessage(error.response?.data?.errors.new_password[0])
                    return;
                }
                setMessage('Unknown error occured')
            })
    }

    return (
        <Container>
            <Row>
                <Col sm={12} md={6}>
                    <h1 className='heading mb-4'>Change your password</h1>
                    <Form onSubmit={(e) => { updatePassword(e) }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value)}} required={true}/>
                            <Form.Text className="text-muted">
                            {message}
                            </Form.Text>
                        </Form.Group>
                
                        <Button variant="primary" type="submit" className='w-100'>
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Setting