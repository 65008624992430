import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import routes from "./routes";

import NotFound from './views/NotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

const App = () => (
  <div className="app-container">
    <ToastContainer />
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                element={route.layout}
              >
                 <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  > 
                   {
                     route.children?.map((child, idx) => (
                        <Route
                          key={idx}
                          path={child.path}
                          element={child.component}
                        >

                        </Route>
                     ))
                   }  
                  </Route>
              </Route>
            );
          })}
          <Route component={NotFound} />        
        </Routes>
      </Router>
  </div>      
);

export default App;