import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Link } from 'react-router-dom';

import './login.css'
import { loginUserAPI } from '../../services/api';
import { isLoggedIn, setAccessToken } from '../../services/client';


function Login() {
    const errorObject = {
        email: '',
        password: ''
    }

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(errorObject)
    
    const navigate = useNavigate();


    const loginUser = (e) => {
        e.preventDefault()
        if ( !email || !password ) {
            setError({
                email: '',
                password: 'Email & Password are required.'
            })
            return;
        }
        e.target.disabled = true;

        setError(errorObject);
        const user = {
            email: email,
            password: password
        }

        loginUserAPI(user)
            .then(response => (response.data))
            .then(response => {
                e.target.disabled = false;        
                if ( response.email || response.password ) {
                    setError({
                        email: '',
                        password: response.password ? response.password[0] : ''
                    })
                    
                    return;
                }
                setAccessToken(response.access_token)
                navigate('/account/detail');

            }).catch(error => {
                e.target.disabled = false;
                setError({
                    password: 'Invalid email or password'
                })
                console.log(error)
            })
    }

    const submitOnEnter = () => {
        
        document.getElementById('login-form').addEventListener('keydown', function (a) {  
            if (a.key === 'Enter') {
                const login = document.getElementById('login-btn')
                login.click();
            }
        }, false);
    }

    useEffect(() => {
        if( isLoggedIn() ) {
            navigate('/account/detail');
            return;
        }
        submitOnEnter()
    });
    

    return (
    <Container className='mt-4'>
        <Row className='d-flex justify-content-center' style={{paddingTop: '25px'}}>
            <Col lg={4} md={4} sm={12}>
                <h3>Login into your account.</h3>
                <Form id='login-form' className='mt-4'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address *</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value)}}/>
                        <Form.Text className="text-danger">
                            {error.email}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password *</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value)}}/>
                        <Form.Text className="text-danger">
                            {error.password}
                        </Form.Text>
                    </Form.Group>
                    <Button variant="secondary" className='login-btn mt-2' id="login-btn" onClick={(e) => loginUser(e)}>
                        Login
                    </Button>
                    <Button variant="outline-secondary" className='login-btn mt-1'>
                        <Link to='/signup'>Create an Account</Link>
                    </Button>
                    <div className='mt-4'>
                    <Link className='mt-4' to='/forgot-password'>Forget your password?</Link>
                    </div>
                </Form>
            </Col>
        </Row>
    </Container>       
    );
}

export default Login;