import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import { isLoggedIn, Logout } from '../../../services/client';

function AccountNavbar(props) {
  const { activeLink, setActiveLink, hideoffCanvas } = props;
  const logged_in = isLoggedIn();

  return (
        <Nav>                                   
            <Link to="/license" className={`nav-link text-black ${activeLink === 'license' ? 'active' : ''}`} onClick={() => {
                setActiveLink('license')
                hideoffCanvas()
                }}>License</Link>
            {
                logged_in && 
                <>
                    <Link to="/account/detail" className={`nav-link text-black ${activeLink === 'detail' ? 'active' : ''}`} onClick={() => {
                        setActiveLink('detail')
                        hideoffCanvas()
                        }}>My Account</Link>
                    <Nav.Link href="#" className='text-black' onClick={() => { Logout() }}>
                        Logout
                    </Nav.Link>
                </>
                    
            }                                                           
        </Nav>   
  );
}

export default AccountNavbar;