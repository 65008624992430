import Spinner from 'react-bootstrap/Spinner';

function ProgressLoader({size = 'lg'}) {

  if ( size === 'lg' ) {
    return <Spinner animation="border" size="lg"/>
  }
  
  return <Spinner animation="border" size="sm"/>
}

export default ProgressLoader;