import { BoxedLayout } from '../../layout';
import Feature from './Feature/Feature';

import './features.css'

import person_on_aircraft from '../../assets/person_on_aircraft.png'
import college_student from '../../assets/college_student.png'
import cockpit from '../../assets/cockpit.png'
import pilot_safety from '../../assets/pilot_safety.png'


function Features() {
  const features = [{
    image: college_student,
    title: 'Spend less',
    text: 'Learning outside of the cockpit using Virtual Flight Instructor is the most lifelike recreation of actual flying. And it is the most cost effective solution to get tens or hundreds of hours logged practicing a near limitless number of conditions including high-stakes scenarios that are too dangerous to practice in the air. ',
    className:'flex-lg-row'
  },
  {
    image: cockpit,
    title: 'Graduate faster',
    text: 'The most dedicated students eat, sleep, and dream flight. All students will learn to visualize flight patterns and practice in their brain. Virtual Flight Instructor drives engagement and enables deliberate practice of facilitated routines in a way that has not been cost affordable until now. ',
    className:'flex-lg-row-reverse'
  },
  {
    image: pilot_safety,
    title: 'Improve safety',
    text: 'Reactive muscle memory reduces mistakes. Repetitive practice in real-world circumstances builds muscle memory. Time spent flying routine maneuvers creates hard wired neural pathways between the brain and your muscles. Split second instinctive correct actions create safety and world class pilots.',
    className:'flex-lg-row'
  }]

  return (
    <div className='features' id="features">
        <BoxedLayout>
          <div className='d-flex flex-column vfi-family-dm-sans fst-normal'>
            <img className='person_on_aircraft' src={person_on_aircraft} alt="Pilot"></img>
            {
              features.map((feature, idx) => ( <Feature key={`feature-${idx}`} feature={feature} />))
            }
          </div>
        </BoxedLayout>
    </div>        
  );
}

export default Features;