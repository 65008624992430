import './feature.css'

function Feature({feature}) {
    const {title, image, text, className} = feature
    return (
        <div className={`feature d-flex flex-column ${className} justify-content-between vfi-family-dm-sans`}>
            <div className='content d-flex flex-column justify-content-between'>
                <span className='title vfi-text-light-black'>{title}</span>
                <span className='text'>{text}</span>
            </div>
            <img src={image} alt={title} />
        </div>        
    );
}

export default Feature;