import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProgressLoader from '../../../components/ProgressLoader/ProgressLoader';

import { getCardAPI, removeCardAPI, changeCardAPI, getPaymentIntentAPI } from '../../../services/api';

import './paymentmethods.css'

import { loadStripe } from '@stripe/stripe-js';
import { stripe_pk } from '../../../config';
import { notify } from '../../../services/client';

function PaymentMethods() {
  const [card, setCard] = useState(null)

  const [error, setError] = useState(null)
  const [disableBtn, setDisableBtn] = useState(false)
  const [loadCard, setLoadCard] = useState(false)

  const [stripeData, setStripeData] = useState(null)
  const [paymentElementData, setPaymentElementData] = useState(null)
  const [clientSecretData, setClientSecretData] = useState(null)

  const [showChangeCardModal, setShowChangeCardModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  
  const handleCloseChangeCardModal = () => setShowChangeCardModal(false);
  const handleShowChangeCardModal = () => setShowChangeCardModal(true);

  const handleCloseDeleteConfirmationModal = () => setShowDeleteConfirmationModal(false);
  const handleShowDeleteConfirmationModal = () => setShowDeleteConfirmationModal(true);

  
  const getPaymentMethod = () => {
    setLoadCard(true);
    setError('')
    getCardAPI()
      .then(response => (response.data))
      .then((data) => {
        setLoadCard(false);
        if (!data.success) {
          setError(data.message)
          return;
        }
        setCard(data.card)
      })
      .catch((error) => {
        console.log(error)
      })
      
  }
  
  const changeCard = () => {
    handleShowChangeCardModal()
    setLoadingStripe(true)
    
    getPaymentIntentAPI()
        .then(response => (response.data))
        .then(async (data) => {
            const client_secret = data.intent.client_secret

            const stripe = await loadStripe(stripe_pk);
            setStripeData(stripe)
            
            const elements = stripe.elements({
                clientSecret: client_secret,
            });
            
            const paymentElement = elements.create('card');            
            paymentElement.mount('#card-element'); 
            paymentElement.on('ready', () => {
              paymentElement.focus()
            });
            setClientSecretData(client_secret);
            setPaymentElementData(paymentElement);
            setLoadingStripe(false);
        })
        .catch(error => {
            setLoadingStripe(false);
            console.log(error)
        })
  }
  
  const deleteCard = (e) => {
    handleShowDeleteConfirmationModal()
  }

  const confirmRemoveCard = (e) => {
    e.target.disabled = true;
    removeCardAPI()
      .then(response => (response.data))
      .then((data) => {
        setLoadingStripe(false)
        notify(data.message, 'success');
        handleCloseDeleteConfirmationModal()
        setError('No payment method attached to the account')
        setCard(null)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateCard = (e) => {
    setDisableBtn(true)
    stripeData
        .confirmCardSetup(clientSecretData, {
            payment_method: {
                card: paymentElementData
            },
        })
        .then(function(result) {
            const displayError = document.getElementById('card-errors');
            if ( result.error ) {
                displayError.textContent = result.error.message;
                setDisableBtn(false);
                return;
            }
            displayError.textContent = '';
            const data = {
                paymentMethod: result.setupIntent.payment_method
            }
            changeCardAPI(data)
                .then(response => (response.data))
                .then(data => {
                    paymentElementData.update({ disabled: false });
                    setLoadingStripe(false)
                    setDisableBtn(false)
                    notify(data.message, 'success');
                    getPaymentMethod();
                    handleCloseChangeCardModal();
                })
                .catch(error => {
                    paymentElementData.update({ disabled: false });
                    setLoadingStripe(false)
                    setDisableBtn(false)
                    console.log(error)
                })
        });
  }

  useEffect(() => {
    getPaymentMethod()
  },[]);

  return (
    <Container>
        <h1 className='heading'>Payment Methods</h1>
        <div className="billed-to">
            <Row>
                <Col lg={6} md={6}>
                    {
                      loadCard && 'loading..'
                    }
                    {
                      !loadCard && !error && card &&
                        <><span className="static"> This Account is billed to </span><br/><span className="card-details">{card.brand} ending at {card.last4}</span></>
                    }
                    {
                      !loadCard && error && !card &&
                      <><span className="static text-danger"> {error} </span></>
                    }
                </Col>
                <Col md={3} lg={3}>
                  
                    {
                      card ? (<span className="remove-cc" onClick={(e) => { deleteCard(e)}}>Remove credit card</span>) :''
                    }
                        
                </Col>
                <Col md={3} lg={3}>
                    <span className="change-cc" onClick={(e) => {changeCard(e)}}>
                      {
                        card ? 'Change credit card' : 'Add credit card'
                      }
                    </span>
                </Col>
            </Row>
            <Modal show={showChangeCardModal} onHide={handleCloseChangeCardModal} centered>
                <Modal.Header closeButton>
                <Modal.Title>{card ? 'Update' : 'Add'} Credit/Debit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <div id="card-element"></div>
                    <div id="card-errors" className="text-danger"></div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => {handleCloseChangeCardModal()}}>
                      Cancel
                  </Button>
                  <Button variant="primary" onClick={(e) => { updateCard(e) }} disabled={disableBtn}>
                      {
                        card ? 'Update' : 'Add'
                      }
                  </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteConfirmationModal} onHide={handleCloseDeleteConfirmationModal} centered>
                <Modal.Header closeButton>
                <Modal.Title>Delete Credit/Debit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <p>Are you sure to delete your Credit/Debit Card?</p>
                </Modal.Body>
                <Modal.Footer>
                  {
                      loadingStripe && <ProgressLoader size="sm"/> 
                  }
                  <Button variant="secondary" onClick={() => {handleCloseDeleteConfirmationModal()}}>
                      Cancel
                  </Button>
                  <Button variant="danger" onClick={(e) => { confirmRemoveCard(e)}}>
                      Delete
                  </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </Container>      
  );
}

export default PaymentMethods;