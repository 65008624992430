import React from "react";

import { Container } from "react-bootstrap";

const BoxedLayout = ({children}) => (
    <Container>
        {children}
    </Container>
);

export default BoxedLayout;