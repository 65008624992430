import { BoxedLayout } from '../../layout';

import './howitworks.css'

import howitworks_bg from '../../assets/howitworks_bg.png'

function HowItWorks() {
  const steps = [
    'Sign up for Virtual Flight Instructor.',
    'Install X-plane and VFI.',
    'Practice.',
    'Sign up for flight training with a partner school and get VFI included with your tuition.'
  ]

  return (
    <div className='howitworks vfi-family-dm-sans' style={{backgroundImage: `url(${howitworks_bg})`}}>
        <BoxedLayout>
          <span className='title vfi-text-light-black fst-normal'>How it works</span>
          <ol>
            {
              steps.map((step, idx) => (<li className='vfi-text-black' key={`step-${idx}`}>{step}</li>))
            }
          </ol>
        </BoxedLayout>
    </div>        
  );
}

export default HowItWorks;