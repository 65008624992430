// Layout Types
import { DefaultLayout, AuthLayout } from "./layout";

// Route Views
import Home from "./views/Home/Home";
import Account from "./views/Account/Account"
import Detail from "./views/Account/Detail/Detail";
import Transactions from "./views/Account/Transactions/Transactions";
import PaymentMethods from "./views/Account/PaymentMethods/PaymentMethods";
import Login from "./views/Login/Login";
import SignUp from "./views/SignUp/SignUp";
import License from "./views/License/License";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import Setting from "./views/Account/Setting/Setting";

const routes = [  
  {
    path: "/",
    component:< Home />,
    layout:  <DefaultLayout is_home={true}/>
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    layout:  <AuthLayout />
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    layout:  <AuthLayout />
  },
  {
    path: "/license",
    component:< License />,
    layout:  <DefaultLayout />
  },
  {
    path: "/account",
    component: <Account />,
    layout:  <DefaultLayout/>,
    children: [
      {
        path: "/account/setting",
        component:< Setting />,
        layout:  <Account />,
      },
      {
        path: "/account/detail",
        component:< Detail />,
        layout:  <Account />,
      },
      {
        path: "/account/transactions",
        component:< Transactions />,
        layout:  <Account />,
      },
      {
        path: "/account/payment-methods",
        component:< PaymentMethods />,
        layout:  <Account />,
      }
    ]
  },
  {
    path: "/login",
    component:< Login />,
    layout:  <DefaultLayout/>
  },
  {
    path: "/signup",
    component:< SignUp />,
    layout:  <DefaultLayout/>
  },
  {
    path: "/signup/:package_id",
    component:< SignUp />,
    layout:  <DefaultLayout/>
  }
];

export default routes;