import axios from 'axios'
import { getAccessToken, Logout } from '../client';

import { api_url } from '../../config';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = getAccessToken();
    if ( token ) {
        config.headers.Authorization =  `Bearer ${token}`;
    }     
    return config;
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
    }, function (error) {
    const pathname = window.location.pathname;
    if ( !pathname.includes('login') && !pathname.includes('signup') && error.response?.status === 401 ) {
        Logout();
        return;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export const loginUserAPI = async (user) => {
    return await axios.post(`${api_url}/auth/login`, user)
}

export const signUpUserAPI = async (user) => {
    return await axios.post(`${api_url}/auth/signup`, user)
}

export const updatePasswordAPI = async (obj) => {
    return await axios.post(`${api_url}/auth/update-password`, obj)
}

export const sendResetPasswordEmailAPI = async (obj) => {
    return await axios.post(`${api_url}/auth/forgot-password`, obj)
}

export const resetPasswordAPI = async (obj) => {
    return await axios.post(`${api_url}/auth/reset-password`, obj)
}

export const getPlansAPI = async () => {
    return await axios.get(`${api_url}/subscription/plans`)
}

export const getPaymentIntentAPI = async () => {
    return await axios.get(`${api_url}/payment/intent`)
}

export const createSubscriptionAPI = async (obj) => {
    return await axios.post(`${api_url}/subscription/create`, obj)
}

export const getSubscriptionAPI = async () => {
    return await axios.get(`${api_url}/subscription/get`)
}

export const getInvoicesAPI = async () => {
    return await axios.get(`${api_url}/subscription/invoices`)
}

export const renewSubscriptionAPI = async (obj) => {
    return await axios.post(`${api_url}/subscription/renew`, obj)
}

export const cancelSubscriptionAPI = async (obj) => {
    return await axios.delete(`${api_url}/subscription/cancel`, obj)
}

export const disableAutoRenewSubscriptionAPI = async (obj) => {
    return await axios.post(`${api_url}/subscription/disable`, obj)
}

export const switchToAnnualBillingAPI = async (obj) => {
    return await axios.post(`${api_url}/subscription/switch`, obj)
}

export const getCardAPI = async () => {
    return await axios.get(`${api_url}/payment`)
}

export const changeCardAPI = async (obj) => {
    return await axios.post(`${api_url}/payment/update`, obj)
}

export const removeCardAPI = async (obj) => {
    return await axios.delete(`${api_url}/payment/delete`, obj)
}