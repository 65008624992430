import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../../components/Sidebar/Sidebar';


function Account() {
  const [ sidebarItems ] = useState([
    {
      title: 'Subscription',
      link: '/account/detail'
    },
    {
        title: 'Transactions / Invoices',
        link: '/account/transactions'
    },
    {
        title: 'Payment Methods',
        link: '/account/payment-methods'
    },
    {
      title: 'Settings',
      link: '/account/setting'
    }
  ]);

  return (
    <Container>
      <Row style={{marginTop: '5rem'}}>
        <Col md={2} lg={2}>
          <Sidebar items={sidebarItems} />
        </Col>
        <Col md={10} lg={10}>
            <Outlet/>
        </Col>
    </Row>  
    </Container>  
  );
}

export default Account;