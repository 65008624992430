import Navbar from 'react-bootstrap/Navbar';

import './footer.css'

function Footer({is_home = false}) {
  return (
    <Navbar bg="light" expand="lg" fixed={is_home ? '' : 'bottom'} className='end-0'>
          <p className='copy-right w-100 text-center'>Copyright © 2023 - 2023 VFI</p>
    </Navbar>
  );
}

export default Footer;