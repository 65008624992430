import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Button  from 'react-bootstrap/Button';
import ProgressLoader from '../../../components/ProgressLoader/ProgressLoader';

import { getSubscriptionAPI, cancelSubscriptionAPI, renewSubscriptionAPI, 
  disableAutoRenewSubscriptionAPI, switchToAnnualBillingAPI } from '../../../services/api';

import './detail.css'
import { notify } from '../../../services/client';
import { osx_url, windows_url, sop_url } from '../../../config';

function Detail() {
  const hidden_value = '***************';
  
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [licenseText, setLicenseText] = useState(hidden_value);
  const [licenseTo, setLicenseTo] = useState('');
  const [license, setLicense] = useState('');
  const [nextBillingDate, setNextBillingDate] = useState('');
  const [lineText, setLineText] = useState('');
  const [copyBtnText, setCopyBtnText] = useState('Copy');
  const [showBtnText, setShowBtnText] = useState('Show');


  const getSubscription = () => {
    getSubscriptionAPI()
      .then(response => (response.data))
      .then((data) => {
        if ( data.length === 0) {
          navigate('/license');
          return;
        }
        const { line_1, licenseKey, licenseTo, nextBilling_date } = data;
        setLineText(line_1)
        setLicense(licenseKey)
        setNextBillingDate(nextBilling_date)
        setLicenseTo(licenseTo)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const switchToAnnualBilling = (e) => {
    e.target.disabled = true;
    switchToAnnualBillingAPI({})
      .then(response => (response.data))
      .then((data) => {})
      .catch((error) => {
        console.log(error)
      })
  }

  const renew = (e) => {
    e.target.disabled = true;
    renewSubscriptionAPI({})
      .then(response => (response.data))
      .then((data) => {})
      .catch((error) => {
        console.log(error)
      })
  }

  const cancel = (e) => {
    e.target.disabled = true;
    cancelSubscriptionAPI({})
      .then(response => (response.data))
      .then((data) => {
          if ( data.success ) {
            notify(data.message, 'success')
            navigate('/license');
            return;
          }
          notify(data.message, 'error')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const disabelAutoRenewal = (e) => {
    e.target.disabled = true;
    disableAutoRenewSubscriptionAPI({})
      .then(response => (response.data))
      .then((data) => {})
      .catch((error) => {
        console.log(error)
      })
  }

  const showLicense = (e) => {
    if ( showBtnText === 'Show' ) {
      setLicenseText(license)
      setShowBtnText('Hide')
      return;
    }
    setLicenseText(hidden_value)
    setShowBtnText('Show')
  }

  const copyLicense = (e) => {
    navigator.clipboard.writeText(license);
    setCopyBtnText('Copied...');
    setTimeout(() => {
      setCopyBtnText('Copy')
    }, 1000);
  }

  useEffect(() => {
    getSubscription()
  });

  return (
    <Container>
      <Row className='subscription'>
        <Col sm={2} md={9} lg={9}>
          <h1 className='heading'>VFI Subscription</h1>
        </Col>
      </Row>
      {
        !loading && 
        <Row className='account-details mx-0'>
          <Row className='mx-0'>
            <Col xs={12} sm={12} md={9} lg={9} xl={9} className="d-flex flex-column data">
              <div className='row'>
                <span className='col-3'>Licensed to:</span><span  className='col-9' id="username">{licenseTo}</span>
              </div>
              <div className='row'>
                <span className='col-3'>Fallback ver:</span><span  className='col-9' >2021.3</span>
              </div>
              <div className='row'>
                <span className='col-md-3 col-lg-3 col-sm-12 col-xs-12'>Next Billing date:</span><span className='col-md-9 col-lg-9 col-sm-12 col-xs-12'>{nextBillingDate}</span>
              </div>
              <div className='row'>
                <span className='col-md-3 col-lg-3 col-sm-12 col-xs-12'>License:</span>
                <span className='col-md-9 col-lg-9 col-sm-12 col-xs-12'>{licenseText} 
                  <Button variant="default" className='action mx-4' onClick={(e) => { showLicense(e)}}>{showBtnText}</Button>
                  <Button variant="default" className='action' onClick={(e) => { copyLicense(e)}}>{copyBtnText}</Button>
                </span>
                
              </div>
              <div className='row my-2'>
                <span className='col-md-3 col-lg-3 col-sm-12 col-xs-12 my-2'>Links to download build:</span>
                <a href={osx_url} target="_blank" title="Download for OSX">Download for OSX</a>
                <a href={windows_url} target="_blank" title="Download for Windows">Download for Windows</a>
                <a href={sop_url} target="_blank" title="Validation SOP">Validation SOP</a> 
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className='d-flex flex-column'>
              {/* <Button variant="default" className='action' onClick={(e) => switchToAnnualBilling(e) }>Switch to annual billing</Button>
              <Button variant="default" className='action' onClick={(e) => renew(e) }>Renew</Button> */}
              <Button variant="default" className='action text-danger' onClick={(e) => cancel(e) }>Cancel subscription</Button>
              {/* <Button variant="default" className='action' onClick={(e) => disabelAutoRenewal(e) }>Disable Auto renewal</Button> */}
            </Col>
          </Row>
          <Row className='mx-0'>
            <Col md={6} lg={6}>
            <span>
              {lineText} 
            </span>
            </Col>
          </Row>
        </Row>
      }
      {
        loading && <ProgressLoader />
      }
    </Container>      
  );
}

export default Detail;