import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import DataTable from 'react-data-table-component';
import DownloadBtn from '../../../components/DownloadBtn/DownloadBtn';
import ProgressLoader from '../../../components/ProgressLoader/ProgressLoader'

import { getInvoicesAPI } from '../../../services/api';

import './transactions.css'

function Transactions() {
  const [progressPending, setProgressPending] = useState(true);
  const [headers,  setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  const paginationComponentOptions ={
    selectAllRowsItem: true
  }

  const getTransactions = () => {
    getInvoicesAPI()
        .then(response => (response.data))
        .then((data) => {
          let rows = data.map(row => ({
            ref_id: row.number,
            description: row.lines?.data[0].description,
            date: new Date(row.created * 1000).toLocaleDateString("en-US"),
            amount: '$'+(row.total / 100.0),
            receipt: row.invoice_pdf
          }))
          let headers = [{
            name: 'Ref ID',
            slug: 'ref_id'
          },
          {
            name: 'Description',
            slug: 'description'
          },
          {
            name: 'Date',
            slug: 'date'
          },
          {
            name: 'Amount',
            slug: 'amount'
          },
          {
            name: 'Receipt',
            slug: 'receipt'
          }]
      
          headers = headers.map((h)=>{
            switch (h.slug){
                case "description":
                    return {
                        id: h?.slug ? h?.slug : h?.id,
                        name: h.name,
                        slug: h.slug,
                        sortable: false,
                        visible: true,
                        subHeaderWrap:true,
                        width: '350px',
                        selector: row => row[h.slug]
                    }
                case "ref_id":
                  return {
                      id: h?.slug ? h?.slug : h?.id,
                      name: h.name,
                      slug: h.slug,
                      sortable: false,
                      visible: true,
                      subHeaderWrap:true,
                      width: '180px',
                      selector: row => row[h.slug]
                  }
                case "date":
                  return {
                      id: h?.slug ? h?.slug : h?.id,
                      name: h.name,
                      slug: h.slug,
                      sortable: false,
                      visible: true,
                      subHeaderWrap:true,
                      width: '150px',
                      selector: row => row[h.slug]
                  }
                default:
                    return {
                        id: h?.slug ? h?.slug : h?.id,
                        name: h.name,
                        slug: h.slug,
                        sortable: false,
                        visible: true,
                        subHeaderWrap:true,
                        width: '100px',
                        selector: row => row[h.slug]
                    }
            }
          })
      
          setHeaders(headers);      
          rows = rows.map((r) => ({ ...r, receipt: <DownloadBtn url={r.receipt}/> }));
      
          setRows(rows);
          setProgressPending(false);
        })
        .catch((error) => {
          console.log(error)
          setProgressPending(false);
        })
  }

  useEffect(() => {
    getTransactions();
  }, [])

  return (
    <Container>
        <h1 className='heading'>Transactions/Invoices</h1>
        <DataTable
          columns={headers}
          data={rows}
          progressPending={progressPending}
          pagination
          dense
          fixedHeader
          progressComponent={<ProgressLoader/>}
          paginationComponentOptions={paginationComponentOptions}
          defaultSortFieldId={"name"}
          defaultSortAsc={true}
          />
    </Container>      
  );
}

export default Transactions;